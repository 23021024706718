

function Page404 (props){
    return (
        <div>
            <h1>404</h1>
            <h1> Lo sentimos, no hemos podido encontrar lo que buscas xD</h1>
        </div>
    );
}

export default Page404;